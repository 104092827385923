.learnMoreButton {
  display: flex;
  text-transform: none;
  font-style: italic;
}

.learnMoreButton:hover {
  text-decoration: underline;
  color: blueviolet;
}


.descriptionBox {
  display: block; 
  display: -webkit-box;
  height: 155px; 
  margin: 0 auto;
  line-height: 160px;
  -webkit-line-clamp: 8;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}


.tabCompany {
  text-transform: none;
  min-width: 600px;
}

@media only screen and (max-width: 999px)  {
  .tabCompany {
    text-transform: none;
    min-width: 0px;
    font-size: 10px;
  }

  .tabDescription {
    font-size: 10px;
  }

  .descriptionBox {
    display: block; 
    display: -webkit-box;
    height: 100px; 
    margin: 0 auto;
    line-height: 160px;
    -webkit-line-clamp: 8;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
}